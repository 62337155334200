import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import Slider from "../components/slider"
import BlogPosts from "../components/blogposts"
import { Link } from "gatsby"
import Blog from "../templates/blog"

const Home = (props) => {

    return (
        <Layout css_class="home">
            <Head title="Home" />
            <Slider />
            <main>
                <div className="container">
                    <section>
                        <BlogPosts limit={3} data={props} />
                    </section>
                </div>
            </main>
        </Layout>
    )
}

export default Home