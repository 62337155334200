import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import sliderStyles from '../styles/modules/slider.module.scss';

const Slider = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "assets/img/slide.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);
  
    return (
        <div className={sliderStyles.slider}>
          <div className={sliderStyles.overlayColor}></div>
            <div className={sliderStyles.overlayImage} style={{backgroundImage: `url(${data.file.childImageSharp.fluid.src})`}}>
              <div className={sliderStyles.content}>
                  <div className="container">
                      <div className="row">
                          <div className="col">
                            <span>Dont dream</span> of <br/>
                            success
                            <small>work for it...</small>
                            <a href="/blog/" className="button button-primary">Read the blog</a>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    )
}

export default Slider